import React from 'react'

function CopyRightSection() {
    return (
        <>
            <div className="footer_copy">
                <p>© Marquee.Market. 2023 All rights reserved.</p>
                <p><a href="/terms" style={{fontSize: '12px'}}>Terms of Service</a> | <a href="/privacy" style={{fontSize: '12px'}}>Privacy Policy</a></p>
            </div>
        </>
    )
}

export default CopyRightSection