//line number 112 uncomment if not working and comment line number 113
import React, { Component, useEffect, useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import {
    VStack,
    Text,
    Tooltip,
    Heading,
    Button,
    ButtonGroup
} from "@chakra-ui/react";
import { truncateAddress } from "../../../../utils";
import DeleteBannerAd from "./DeleteBannerAd";
import ListHeader from '../ListHeader'
import loadingGif from '../../../../Assets/images/loading.gif'
import trashIcon from '../../../../Assets/images/delete.png'
const OwnerNFTList = (props) => {
    const [loader, setLoader] = useState(true);
    const [assets, setAsset] = useState([]);
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [error, setError] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [name, setName] = useState("");
    const [contract, setContract] = useState("");
    const [nftId, setNftId] = useState(0);
    const [tokenId, setTokenId] = useState(0);
    const [delIds, setDelIds] = useState([]);
    const [pages, setPages] = useState(['']);
    var id = [];

    const openModal = (e, name, id, token_id, contract) => {
        e.preventDefault();
        setIsOpen(true);
        setName(name);
        setNftId(id);
        setTokenId(token_id);
        setContract(contract);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const getAssets = async (p) => {
        setLoader(true);
        try {
            let res = await fetch(process.env.REACT_APP_MARQUEE_URL + "/api/get_assets_v2.php?owner=" + props.account + "&direction=desc&limit=200&cursor=" + p + "&t=" + (new Date().getTime()), {
                method: "GET"
            });
            let resJson = await res.json();

            // get owner banner ids
            let resDel = await fetch(process.env.REACT_APP_MARQUEE_URL + "/api/delete_banner_ad_v2.php?owner=" + props.account + "&t=" + (new Date().getTime()), {
                method: "GET"
            });
            let resJsonDel = await resDel.json();

            if (res.status === 200 && resDel.status === 200) {
                setDelIds(resJsonDel)
                setAsset(resJson.nfts)
                setNext(resJson.next)
                setLoader(false);
            } else {
                setError("Some error occured");
            }
        } catch (error) {
            setError(error);
            setLoader(false);
        }
    }

    const retrievePage = (p) => {
        getAssets(p);
    };

    useEffect(() => {
        getAssets('');
    }, []);

    return (
        <div className="body_wrapper">
            <a name="owner_list"></a>
            <VStack justifyContent="center" alignItems="center">
                {loader ? (
                    <>
                        <img src={loadingGif} alt="Loading" /> <br /> Loading...
                    </>
                ) : (
                    <>
                        {props.account && (
                            <>
                                {assets.length ? (
                                    <>
                                        <div className="container">
                                            <div className="row justify-content-center">
                                                <div className="col-md-12 col-lg-10">
                                                    <div className="table-responsive">
                                                        <Heading textAlign={'center'} as='h4' size='md' margin={'10'}>My Marquee.Market NFTs</Heading>
                                                        <nav aria-label="Page navigation" style={{ margin: '25px', display: 'flex', justifyContent: 'center' }}>
                                                            <ul className="pagination">
                                                                <li className="page-item"><a className="page-link" onClick={() => retrievePage('')}>First</a></li>
                                                                {next && (
                                                                    <li className="page-item"><a className="page-link" onClick={() => retrievePage(next)}>Next</a></li>
                                                                )}
                                                            </ul>
                                                        </nav>
                                                        <table className="table nft_table">
                                                            <ListHeader />
                                                            <tbody>
                                                                {assets.map((asset, i) => {
                                                                    const hashtagRegex = /#([a-zA-Z0-9]+)/g;
                                                                    const values = asset.name.match(hashtagRegex);
                                                                    let num = values[0].slice(1);

                                                                    if (asset.identifier != '48545852315955257722035987061857277749614721563072281782584411755630028652545'
                                                                        && asset.identifier != '48545852315955257722035987061857277749614721563072281782584411754530517024769') {

                                                                        return (
                                                                            <>
                                                                                <tr key={i}>

                                                                                    <td scope="row"><span className="tbl_mobile_title">No. :</span> {values[0].slice(1)}</td>
                                                                                    <td><img src={asset.image_url} /></td>
                                                                                    <td> <span className="tbl_mobile_title">Name : </span>{asset.name}</td>

                                                                                    <td>
                                                                                        <div className="nft_color_main">
                                                                                            <span className="tbl_mobile_title">Color Hex Code : </span>
                                                                                            <div className="nft_color" style={{ background: values[1] }}></div>
                                                                                            <span> {values[1]}</span>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td><span className="tbl_mobile_title">Token ID : </span><Tooltip label={asset.identifier} placement="right">{truncateAddress(asset.identifier)}</Tooltip></td>
                                                                                    <td>

                                                                                        <ButtonGroup variant='outline' spacing='6' padding='10px'>
                                                                                            <Link to={"/Dashboard/" + asset.identifier + "?contract=" + asset.contract + "&thumb=" + asset.image_url}>
                                                                                                <Button colorScheme='blue'>Manage NFT Ad</Button>
                                                                                            </Link>
                                                                                        </ButtonGroup>
                                                                                    </td>
                                                                                    <td>{delIds.includes(parseInt(num)) && (<a href="/owner-dashboard" onClick={(e) => openModal(e, asset.name, num, asset.identifier, asset.contract)}><img style={{ width: '25px', height: '25px' }} src={trashIcon} /></a>)}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <nav aria-label="Page navigation" style={{ margin: '25px' }}>
                                            <ul className="pagination">
                                                <li className="page-item"><a className="page-link" onClick={() => retrievePage('')}>First</a></li>
                                                {next && (
                                                    <li className="page-item"><a className="page-link" onClick={() => retrievePage(next)}>Next</a></li>
                                                )}
                                            </ul>
                                        </nav>
                                    </>
                                ) : (
                                    <>
                                        <p><b>You currently do not own any Marquee.Market NFTs.</b></p>
                                        <Link to={"/Purchase"}>
                                            <Button colorScheme='blue'>Purchase a Marquee.Market NFT</Button>
                                        </Link>
                                    </>
                                )}
                                {/*<Text><pre>{JSON.stringify(assets, null, "\t")}</pre></Text>**/}
                            </>
                        )}
                    </>


                )}
            </VStack>
            <DeleteBannerAd account={props.account} library={props.library} isOpen={modalIsOpen} closeModal={closeModal} nftName={name} nftId={nftId} tokenId={tokenId} contract={contract} />
        </div>
    )
}

export default OwnerNFTList;
