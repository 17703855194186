import React from 'react'
import Privacy from '../components/Privacy/Privacy'
import PageNav from '../components/PageNavHeroSection/PageNav'

function PrivacyPage() {
    return (
        <>
            <PageNav heroTitle={'Privacy Policy'} heroDiscription={'Respecting your privacy: our policy explained'} isHero={true} />
            <div className='all-page-margin-bottom'>
            <Privacy />
            </div>
        </>
    )
}

export default PrivacyPage