import React, { useEffect, useState, useMemo } from 'react';
import {
  VStack,
  Text,
  Tooltip,
  Button,
  ButtonGroup,
  Heading,
  Link
} from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import { truncateAddress } from "../../utils";
import TableHeader from './TableHeader';
import loadingGif from '../../Assets/images/loading.gif'
const Purchase = () => {
  const [loader, setLoader] = useState(true);
  const [assets, setAsset] = useState([]);
  const [error, setError] = useState("");
  const [next, setNext] = useState("");
  const [previous, setPrevious] = useState("");

  const getAssets = async (p) => {
    setLoader(true);
    try {
      let res = await fetch(process.env.REACT_APP_MARQUEE_URL + "/api/get_assets_v2.php?direction=asc&limit=200&cursor=" + p + "&t=" + (new Date().getTime()), {
        method: "GET"
      });

      let resJson = await res.json();
      if (res.status === 200) {
        setNext(resJson.next);
        setAsset(resJson.nfts);
        setLoader(false);
      } else {
        setError("Some error occured");
      }
    } catch (error) {
      setError(error);
      setLoader(false);
    }
  }

  const retrievePage = async (p) => {
    await getAssets(p);
  };

  useEffect(() => {
    getAssets('');
  }, []);

  return (
    <ChakraProvider>
      <div className="body_wrapper">
        <VStack justifyContent="center" alignItems="center" h="100%" style={{ padding: '20px' }}>
          {loader ? (
            <>
              <img src={loadingGif} alt="Loading" /> <br /> Loading...
            </>
          ) : (
            <>
              <Heading as='h4' size='md'>Van Gogh's Starry Night Edition MM NFTs</Heading>
              <div className='container page-link-center'>
                <nav aria-label="Page navigation" style={{ margin: '25px' }}>
                  <ul className="pagination">
                    <li className="page-item"><a className="page-link" onClick={() => retrievePage('')}>First</a></li>
                    {next && (
                      <li className="page-item"><a className="page-link" onClick={() => retrievePage(next)}>Next</a></li>
                    )}
                  </ul>
                </nav>
              </div>
              <br />
              <div className="container table-responsive">
                <table className="table nft_table">
                  <TableHeader />
                  <tbody>
                    {assets.map((asset, i) => {
                      const hashtagRegex = /#([a-zA-Z0-9]+)/g;
                      const values = asset.name.match(hashtagRegex);

                      return (
                        <>
                          <tr key={i}>
                            <td scope="row"><span className="tbl_mobile_title">No. :</span> {values[0].slice(1)}</td>
                            <td><img src={asset.image_url} /></td>
                            <td><span className="tbl_mobile_title">NFT Name :</span> {asset.name}</td>

                            <td>
                              <div className="nft_color_main">
                                <span className="tbl_mobile_title">Color Hex Code : </span>
                                <div className="nft_color" style={{ background: values[1] }}></div>&emsp;
                                {values[1]}
                              </div>
                            </td>

                            <td><span className="tbl_mobile_title">Token ID : </span> <Tooltip label={asset.identifier} placement="right">{truncateAddress(asset.identifier)}</Tooltip></td>
                            {/* <td><span className="tbl_mobile_title">Total Unique Visits : </span>500</td> */}
                            <td><a className="btn_visit" onClick={() => window.open(asset.opensea_url)}>Purchase</a></td>
                          </tr>
                        </>
                      )
                    })}

                  </tbody>
                </table>
              </div>
              <div className='container page-link-center'>
                <nav aria-label="Page navigation" style={{ margin: '25px' }}>
                  <ul className="pagination">
                    <li className="page-item"><a className="page-link" onClick={() => retrievePage('')}>First</a></li>
                    {next && (
                      <li className="page-item"><a className="page-link" onClick={() => retrievePage(next)}>Next</a></li>
                    )}
                  </ul>
                </nav>
              </div>
              {/*<Text><pre>{JSON.stringify(assets, null, "\t")}</pre></Text>*/}
            </>
          )}
        </VStack>
      </div>
    </ChakraProvider>
  )
}

export default Purchase;
