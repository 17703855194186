import React from 'react'
import nft_user from '../../../Assets/images/images/nft_user.jpg'
import { Link } from "@chakra-ui/react";
function BlogAuthor() {
    return (
        <>
            <div class="blog_content_box blog_founder">
                <div>
                    <img src={nft_user} alt="" />
                </div>
                <div>
                    <h4>Gregory Rzeczko</h4>
                    <h5>Founder</h5>
                    <p>Gregory Rzeczko is a full-stack software engineer working with Web3 (NFT and Crypto), AI, robotics, and a plethora of many other languages for over 20 years professionally. He is also a musician who goes by the artist name <Link target="_blank" href="https://soundcloud.com/triple-g-gregg-starski">(Triple G) Gregg Starski</Link>. On his spare time he loves to read about history, religion/spirituality, rocket science, technology, and many other subjects. <Link target="_blank" href="/about">Click here to find out more about Gregory Rzeczko</Link></p>
                </div>
            </div>
        </>
    )
}

export default BlogAuthor