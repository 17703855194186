import React from 'react'
import Terms from '../components/Terms/Terms'
import PageNav from '../components/PageNavHeroSection/PageNav'

function About() {
    return (
        <>
            <PageNav heroTitle={'Terms of Service'} heroDiscription={'Your guide to using our platform safely and responsibly'} isHero={true} />
            <div className='all-page-margin-bottom'>
            <Terms />
            </div>
        </>
    )
}

export default About