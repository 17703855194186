import React from 'react'
import indexLeft from '../../../Assets/images/images/index_left.jpg'
function ImageSection() {
    return (
        <>
            <div className="col-md-12 col-lg-6">
                <img className="index_img img-fluid" src={indexLeft} alt="" />
            </div>
        </>
    )
}

export default ImageSection