import BlogImage from '../../Assets/images/images/nft1_lg.jpeg'
import BlogImage_sm from '../../Assets/images/images/nft1.jpeg'
import BlogImage2 from '../../Assets/images/images/nft2_lg.jpg'
import BlogImage2_sm from '../../Assets/images/images/nft_blog.jpg'
const ServiceData = {
    rpost:[
        {
            id: '01',
            image: BlogImage_sm,
            ptitle: 'Marquee.Market has Launched!',
            date: 'June 13, 2023',
            link: 'Marquee_Market_has_Launched'
        },
    ],
    Blist: [
        {
            id: '01',
            image: BlogImage,
            btitle: 'Marquee.Market has Launched!',
            bdescription: 'Introducing Marquee.Market (MM), the groundbreaking web3 advertising platform that offers you a once-in-a-lifetime opportunity to own a piece of digital real estate like never before! Prepare to be captivated by the immense possibilities and unparalleled value that await you as an MM NFT owner.',
            pdate: '13',
            Month: 'June',
            year: '2023',
            link: 'Marquee_Market_has_Launched'
        },
    ],
    work: [
        {
            id: '01',
            icon: 'ti-settings one',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '02',
            icon: 'ti-heart-broken two',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '03',
            icon: 'ti-target  three',
            title: 'Live page builder',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        }
    ]
    ,
    singleBlogs:[
        {
            id: '01',
            title:'Marquee.Market has Launched!',
            fulldate:' June 13, 2023',
            image:BlogImage,
            date:'13',
            month:'June',
            discription:`<p>Introducing Marquee.Market (MM), the groundbreaking web3 advertising platform that offers you a once-in-a-lifetime opportunity to own a piece of digital real estate like never before! Prepare to be captivated by the immense possibilities and unparalleled value that await you as an MM NFT owner.</p>
            
            <p>Picture this: driving down the virtual highway of the digital world, passing eye-catching billboards. But instead of being mere spectators, you have the power to become the owner and advertiser on these coveted digital billboards. How extraordinary is that? With MM, you can secure your digital ad space through non-fungible tokens (NFTs) and unlock a world of limitless potential.</p>
            
            <p>These MM NFTs are more than just digital assets; they are the keys to a thriving ecosystem where dreams become reality. As an MM NFT owner, you possess the ability to shape your own destiny and skyrocket your brand's visibility. Imagine the impact of having your advertisements seen by a vast audience, boosting your website or app's traffic to unprecedented levels.</p>
            
            <p>But that's not all. MM NFTs are not just static tokens; they are dynamic investments. Each MM NFT grants you full access to Marquee.Market's unparalleled suite of features. Upload your own banners, craft captivating descriptions, and immerse yourself in a <a href="/Forum">vibrant community forum</a> exclusive to MM NFT owners. Collaborate, exchange ideas, and vote for the most exciting enhancements to be implemented by our team of ingenious engineers. By actively contributing to the growth of the Marquee.Market community, you'll witness the value of your MM NFT soar to new heights, presenting you with remarkable opportunities for future resale and financial success.</p>
            
            <p>Now, let's delve into the sheer exclusivity of this proposition. We're searching for the most influential individuals in the NFT space, the luminaries who possess the power to captivate, inspire, and ignite change. If you are an NFT influencer, promoter, or a Web3 aficionado with a significant following, this is your golden moment. We have reserved a limited edition of 24 MM NFTs exclusively for those who can demonstrate their unwavering passion and commitment to promoting NFTs and web3 technology. Imagine being one of the select few to own an MM NFT, a digital masterpiece in its own right, a testament to your influence and vision.</p>
            
            <p>At this moment, only the first 240 MM NFTs are listed for sale for 0.1 ETH. This is due to gas fees and scaling MM. Rest assured that as we continue to refine and expand our infrastructure, more MM NFTs will be made available in the near future. For a comprehensive understanding of this innovative endeavor, we encourage you to explore our <a href="/faq">frequently asked questions (FAQ)</a> section and dive into our <a href="/blog-list">captivating blog</a>. These valuable resources will provide you with deeper insights into the intricate workings of Marquee.Market, shedding light on the rationale behind our current approach and illuminating the boundless potential that lies ahead.</p>
            
            <p>This is your chance to be part of something truly extraordinary. The MM NFT is not just a token; it's a symbol of your pioneering spirit, a bold declaration that you are at the forefront of the web3 revolution. The world will witness your name alongside the exceptional individuals who shape the destiny of this emerging landscape. Together, we will revolutionize the way digital advertising operates, and your MM NFT will forever serve as a testament to your foresight and audacity.</p>
            
            <p>Don't wait a moment longer. Seize this unparalleled opportunity to <a href="/purchase">own an MM NFT</a>,
            
             secure your place in history, and embark on this exhilarating journey with us.</p>
            
            <p>Gregory</p>
            
            <p>At this moment, only the first 240 MM NFTs are listed for sale for 0.1 ETH. This is due to gas fees and scaling MM. Please read <a href="/faq">FAQ</a> and <a href="/blog-list">Blog</a> for more info.</p>`,
            slug:'Marquee_Market_has_Launched'

        },
        {
            id: '02',
            title:'Lorem ipsum dolor sit amet, consectetur 5 adipiscing elit.',
            fulldate:' June 24, 2022',
            image:BlogImage,
            date:'24',
            month:'June',
            discription:`<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed arcu tellus, congue sit amet nisl at, sodales congue nisi. Aliquam quis pulvinar ex. Integer enim sem, accumsan vitae porta vel, gravida at magna. Mauris posuere tincidunt mi id facilisis. Etiam euismod velit leo, nec egestas enim cursus sit amet. Proin tellus sapien, pulvinar a ante eu, lacinia rutrum sapien. Duis consequat nisi orci.</p>
            <p>Quisque molestie fermentum tellus et fermentum. Praesent dui mauris, volutpat id quam nec, vehicula pulvinar erat. Vivamus at vulputate purus. Morbi placerat enim sollicitudin pharetra dapibus. </p>
            <p>Curabitur ac nunc leo. Ut rutrum sapien erat, dictum vestibulum nisl pulvinar ac. Aliquam pretium nibh ante, vitae efficitur augue placerat a. Morbi eu enim erat. Phasellus mattis eleifend magna a euismod. Pellentesque consectetur nulla vitae lorem tempus sagittis. Aenean ac mi vitae ligula placerat dictum. Curabitur maximus malesuada lorem vitae ultricies. Fusce tristique turpis neque, id facilisis libero mollis eget. Nunc libero arcu, maximus ultricies efficitur vitae, scelerisque eget felis. Sed ac lobortis metus.</p>

            <h4>Sample Heading</h4>
            <p>Praesent dui mauris, volutpat id quam nec, vehicula pulvinar erat. Vivamus at vulputate purus. Morbi placerat enim sollicitudin pharetra dapibus. </p>
            <p>Curabitur ac nunc leo. Ut rutrum sapien erat, dictum vestibulum nisl pulvinar ac. Aliquam pretium nibh ante, vitae efficitur augue placerat a. Morbi eu enim erat. Phasellus mattis eleifend magna a euismod. Pellentesque consectetur nulla vitae lorem tempus sagittis. Aenean ac mi vitae ligula placerat dictum. Curabitur maximus malesuada lorem vitae ultricies. </p>
            <p>Cras sit amet lacus justo. Quisque vulputate id felis vel euismod. Sed eu porttitor sem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla ultrices purus at magna malesuada, eu congue est feugiat. Donec lectus nulla, molestie vitae interdum id, euismod non velit. Aliquam in varius orci. In non nisl felis. Donec lorem erat, sagittis eget tincidunt varius, congue in velit. Ut efficitur consequat eros vitae condimentum. Nulla non turpis id magna elementum vulputate eget et libero.</p>

            <h4>Sample Heading</h4>
            <p>Cras sit amet lacus justo. Quisque vulputate id felis vel euismod. Sed eu porttitor sem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla ultrices purus at magna malesuada, eu congue est feugiat. Donec lectus nulla, molestie vitae interdum id, euismod non velit. Aliquam in varius orci. In non nisl felis. Donec lorem erat, sagittis eget tincidunt varius, congue in velit. Ut efficitur consequat eros vitae condimentum. Nulla non turpis id magna elementum vulputate eget et libero.</p>
            <p>Aliquam odio ipsum, ullamcorper a mauris eu, commodo dignissim quam. Aliquam non ex justo. In at euismod elit, at congue felis. Etiam fermentum ipsum a lorem tincidunt, sed placerat ante luctus. Quisque sodales tincidunt elit vitae finibus. Maecenas viverra neque a arcu porttitor, et pulvinar felis varius. Morbi luctus egestas nisi nec semper. Proin a fermentum magna. Nunc ut tellus non velit hendrerit consequat in et odio. Etiam aliquet lacinia turpis. Proin consectetur ante id felis fringilla, a lobortis sem scelerisque. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>`,
            slug:'Marquee_Market_has'

        }
    ],
    faqGeneral:[
        {
            id:'101',
            title:'What is an NFT?',
            discription:'An NFT (non-fungible token) represents digital ownership of an asset, such as an image, video, or music file.',
            show:true
        },
        {
            id:'102',
            title:'What is an MM NFT?',
            discription:'MM NFT is short for “Marquee.Market Non-Fungible Token,” a digital key that gives its owner writing access to features at Marquee.Market. These features include the ability to upload banner ads, pen forum posts, or vote on the site.',
            show:false
        },
        {
            id:'103',
            title:' What is Web3?',
            discription:' Web3 lets you take ownership of digital assets, which in this case is a digital key. With this MM NFT digital key, you have permission to access features at Marquee.Market that others cannot. Web1 was dominated by reading items like static blogs and articles; Web2 offered reading and writing – think social media and the ability to upload photos; and Web3 offers reading, writing, and ownership. An MM NFT digital key gives owners reading and writing permissions to access features at Marquee.Market.',
            show:false
        }, {
            id:'104',
            title:' Why would anyone be interested in purchasing an MM NFT?',
            discription:"Owning an MM NFT is an investment. After you purchase a car or a house, you can later sell it for profit or for a loss, depending on supply-and-demand market value. Currently, only 1,024 MM special Van Gogh Starry Night edition NFTs have been released. MM NFT owners can upload ads at Marquee.Market to bring traffic to their websites at no additional cost. Depending on the amount of traffic, the demand for your MM NFT will determine the market value. Since it's the first release, I am only selling MM NFTs for 1 ETH. If you get lots of traffic and your MM NFT becomes desirable to website owners, you can sell your MM NFT for a profit. The more traffic your website receives, the more valuable your MM NFT is.",
            show:false
        }, {
            id:'105',
            title:'What if no one purchases an MM NFT for the initial price of 1 ETH?',
            discription:'I will give it some time. If no one does, I will keep lowering the price until the first Van Gogh Starry Night MM NFT is purchased. Whatever price the first Starry Night MM NFT goes for will be the locked price for these MM NFTs. But if someone buys the first for 1 ETH, then 1 ETH will be the final immutable price for the Starry Night MM NFTs.',
            show:false
        }, {
            id:'106',
            title:' Are refunds available?',
            discription:'No. As with stock, you will have to resell the MM NFTs at market value or at auction on an NFT marketplace, such as GameStop or OpenSea.',
            show:false
        }, {
            id:'107',
            title:'Who is the founder?',
            discription:` My name is Gregory Rzeczko. I'm a 40-year-old professional software engineer living and working in Brooklyn, NY. For over 20 years, I have been professionally developing software – Web3, AI, and many programming languages, both front-end and back-end. My vision is for Marquee.Market to be the Web3 of online advertising. For more about me, <a href="/#/About">read my bio</a> or visit <a href="https://www.linkedin.com/in/gregory-rzeczko-3076b63/" target="_blank">my LinkedIn profile</a>.`,
            show:false
        }, {
            id:'108',
            title:'What will you do once all the MM NFTs are purchased?',
            discription:' Once all the MM NFTs are purchased, I will expand Marquee.Market by hiring a team of professional engineers to help build what can be the Web3 of online advertising. We will then continue improving upon and building new features for website owners that will bring in more traffic. More traffic and expansion equals more value for MM NFTs.',
            show:false
        }, {
            id:'109',
            title:' What future features do you plan on adding for Marquee.Market?',
            discription:'Some features will include: publishers earning crypto for featuring Marquee.Market ads on their websites or apps; increasing the number of available MM NFTs once popularity rises; and giving MM NFT owners the ability to rent out ad space for residual income. Since some ideas might be great and others not, MM NFT owners are free to discuss them as well as other suggestions in the owner discussion forum or vote for the best ideas. The success of MM NFT owners is the success of Marquee.Market, and vice versa.',
            show:false
        }, {
            id:'110',
            title:'Why only 1,024 MM NFTs?',
            discription:"The initial supply for the debut release of Marquee.Market is 1,024 MM NFTs. I'm keeping it very low at first so as not to overwhelm myself and the servers. The plan is to scale Marquee.Market by increasing the availability of MM NFTs through the geometric sequence 1, 2, 4, 8, 16, 32, and so on, like computer memory. Of course, this could always change if we find a better-suited algorithm.",
            show:false
        }, {
            id:'111',
            title:'How do I purchase an MM NFT?',
            discription:'You can view the <a href="/purchase" target="_blank">MM NFTs sale list here</a> or by clicking on any available spot on <a href="/" target="_blank">the ad grid</a>. All special edition Van Gogh Starry Night MM NFTs are listed at OpenSea, an NFT marketplace.',
            show:false
        }, {
            id:'112',
            title:'How do I sell an MM NFT?',
            discription:'You can sell an MM NFT at an NFT marketplace, like <a href="https://opensea.io/collection/marqueemarket-collection" target="_blank">OpenSea</a> or <a href="https://nft.gamestop.com/" target="_blank">GameStop</a>, either at a fixed price or at auction. All 1,024 Starry Night MM NFTs are initially for sale at the OpenSea NFT marketplace, but if you decide to transfer to another NFT marketplace, <a href="/contact-us" target="_blank">contact me</a>, and I will add support for it.'
        }, {
            id:'113',
            title:'Which NFT wallets are supported?',
            discription:`GameStop, MetaMask, Coinbase, and WalletConnect are supported. <a href="/contact-us" target="_blank">Contact me</a> if you are having issues with your NFT wallet or if it's not yet supported.`,
            show:false
        }, {
            id:'114',
            title:' Which NFT marketplaces are supported?',
            discription:`Currently, OpenSea is supported. I will be working on adding the much-anticipated GameStop NFT marketplace once released. If you are selling your MM NFTs at another NFT marketplace, <a href="/contact-us" target="_blank">contact me</a>, and I will gladly add support for it.`,
            show:false
        }, {
            id:'115',
            title: 'How does Marquee.Market make money?',
            discription:'Marquee.Market makes money through MM NFT sales. Any resales will result in a 10% Marquee.Market royalty fee. In the future, we plan on having Marquee.Market ad publishers earn crypto for publishing MM ads on their websites or apps.',
            show:false
        }, {
            id:'116',
            title:'Can I embed Marquee.Market ads on my website?',
            discription:`Yes! You can do so by going to the <a href="/owner-list" target="_blank">NFT Owner List section</a>, and at the bottom, you'll see an embed snippet for your website. Copy and paste this into your HTML code. In the future, we plan on compensating Marquee.Market ad publishers with crypto.`,            
            show:false
        }, {
            id:'117',
            title:'Can I see how much traffic MM NFT owners are getting?',
            discription:'Yes. You can see how much traffic MM NFT owners are getting pretty much anywhere you see an ad on Marquee.Market: either underneath a banner ad in the description, or by hovering over a 10x10 banner grid ad.',
            show:false
        }, {
            id:'118',
            title:'Up to how many MM NFTs can I own?',
            discription:'However many you want.',
            show:false
        }, {
            id:'119',
            title:'Are NFTs a fad?',
            discription:`No. Think of all the real-world digital use cases and infinite possibilities. Some examples include: ownership verification to play a game you own, online voting, or additional application features for digital book and music owners. The sky's the limit with NFTs, and the sky’s the limit here at Marquee.Market! Think of your MM NFT as a digital key with the possibility to open innumerable doors – and authors getting paid for their work! NFTs are here to stay.`,
            show:false
        }, {
            id:'120',
            title: 'Is there going to be targeted advertising in the future?',
            discription:`Targeted advertising is a planned future feature. Let's say you have a niche surfing travel business, and you want to target people interested in surfing and who live in (or are traveling to) your city of business. The better the features on Marquee.Market, the more valuable your MM NFT is. Think of MM NFT ownership as an investment. The more traffic a website receives because of MM NFT ownership, the more valuable an MM NFT becomes.`,
            show:false
        }, {
            id:'121',
            title:' Can I purchase an MM NFT and not upload an ad at Marquee.Market?',
            discription:`Yes, but it would be like owning an empty business lot with no commercial building. If you're selling the business lot, a buyer won't have any sense of how much traffic or how many leads you were receiving. If you had a commercial building, it would be more appealing and create more demand since it would show that your business has a history. The same idea applies at Marquee.Market: If you own an MM NFT and try to resell it, it may sell for a higher price if you can demonstrate a visitor history.`,
            show:false
        }, {
            id:'122',
            title:'What kind of content can I promote with Marquee.Market?',
            discription:' For the initial launch of MM, we’re keeping it PG-13, meaning only content suitable for anyone 13 years of age or older. Think family friendly. In the future, we plan on having targeted advertising for children and for adults.',
            show:false
        }
    ],
    faqNft:[
        {
            id:'123',
            title:'Can I transfer my MM NFT to another NFT marketplace, like GameStop?',
            discription:`Yes. <a href='/contact-us' target="_blank">Contact me</a> if you would like to do so, and I can make any improvements and make sure that everything's working here at Marquee.Market with your NFT marketplace of choice.`,
            show:true
        },{
            id:'124',
            title:'Is my data secure?',
            discription:'Yes! We do not store sensitive information or passwords in our database, only public data. If a breach happens, no sensitive data will leak. In addition, backups are made daily. Your MM NFT is kept secure in your NFT wallet of choice. For write-access permissions at Marquee.Market, you must verify NFT ownership with your NFT wallet.',
            show:false
        },{
            id:'125',
            title:'If I have an idea or want to express an opinion about an idea, how should I do so?',
            discription:'MM NFT owners have access to a priority contact form. You can also post topics in the NFT Owner Forum. Owners will then have the option to vote for the best ideas. The better the features, the more valuable your MM NFT is.',
            show:false
        },{
            id:'126',
            title:`I tried to log in with my GameStop or MetaMask wallet, but the incorrect wallet is loading. What's wrong?`,
            discription:`Temporarily disable your default wallet; this is a known issue that I'm currently working on.`,
            show:false
        },{
            id:'127',
            title:'How does voting take place?',
            discription:'For NFT OwnersWhenever the team at Marquee.Market or any MM NFT owner suggests good ideas, voting will take place in the NFT Owner Dashboard. MM NFT owners will receive an e-mail alert when this happens.',
            show:false
        },{
            id:'128',
            title:'I own an MM NFT, but I’m not on the NFT Owner List. Why not?',
            discription:`For NFT Owners You must upload ads in the <a href='/owner-dashboard' target="_blank">NFT Owner Dashboard</a> in order to be included in the<a href='/owner-list' target="_blank"> NFT Owner List</a>.`,
            show:false
        },{
            id:'129',
            title:`Can I see how many visitors I'm getting from Marquee.Market?`,
            discription:`For NFT Owners Yes. Go to your  <a href='/owner-dashboard' target="_blank">NFT Owner Dashboard</a>, and click on the “Manage NFT add” buttons on any of your MM NFTs. You will then see how much traffic you're receiving from Marquee.Market.`,
            show:false
        },{
            id:'130',
            title:`I'm unable to connect with my MetaMask or GameStop wallet on mobile. What's wrong?`,
            discription:`To connect with MetaMask on your mobile device, use WalletConnect and choose MetaMask as your wallet of choice. GameStop wallet for mobile is not yet supported.`,
            show:false
        },
    ]
}
export default ServiceData;
