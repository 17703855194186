import React from 'react'

function AboutMain() {
    return (
        <>
            <section class="bg_f8 contact_section">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <div class="contect_content_box">
                            <div>
                        <p><em>Last Update: September 6, 2022</em></p>

                        
                        <p><b><u>1.	ACCEPTANCE</u></b></p>
                        
                        <p>a)	These Terms of Use (the "Terms") govern your visits to https://marquee.market (the "Site"). The Site is collectively referred to herein as the "Platform." Soma Sciences and Arts Studio LLC ("we," "us," or "our") owns and operates this Platform.  The term "you" refers to any user of the Platform.</p>
                        
                        <p>b)	These Terms have a force of a legally binding agreement, even if you are simply browsing without intention to contact us or register an account. Privacy Policy is another important document that you should familiarize yourself with because it describes our practices with respect to your personal information. You cannot visit the Platform if you do not agree to these Terms or the Privacy Policy. Sometimes we modify these Terms. We don’t notify users about every change to the Terms but you can see the date of the last update at the top of this page. If you still wish to visit the Platform after said date, that constitutes your agreement to the updates.</p>

                        
                        <p><b><u>2.	NEUTRAL VENUE</u></b></p>
                        
                        <p>a)	<em>Venue.</em> Marquee Market is the world’s first NFT and crypto-based advertising platform that leverages Web3 capabilities to provide fellow crypto/NFT enthusiasts with a revolutionary new way to maintain control and ownership of online ads. Our Platform is a digital key that unlocks permissions for uploading banner ads, writing forum posts, and voting on polls. Users that purchase a Marquee Market NFT can then use it to continue building brands and attracting new leads or sell it for a profit. Our Platform is a neutral venue. WE SHALL NOT BE HELD LIABLE FOR USERS’ DATA, TRANSACTIONS, INTERACTIONS AND INFORMATION.  We are not liable for the consequences of you disclosing your personal information to others.</p>
                        
                        <p>b)	<em>Interactive Features.</em> This Platform includes interactive features that allow users to communicate with us and each other. You agree that, because of the limited nature of such communication, any guidance you may receive can be incomplete and may even be misleading. Therefore, any assistance you may receive using any our Platform’s interactive features does not constitute specific advice and should not be relied upon without further competent independent confirmation.</p>
                        
                        <p>c)	<em>DRIVING.</em> DO NOT USE THE PLATFORM IN ANY MANNER THAT DISTRACTS YOU FROM DRIVING OR IS ILLEGAL (E.G., IN JURISDICTIONS THAT DO NOT ALLOW THE USE OF MOBILE DEVICES WHILE DRIVING). WE SHALL NOT BE LIABLE FOR YOUR COMPLIANCE WITH TRAFFIC LAWS.</p>
                        
                        <p>d)	<em>Third Parties.</em> The Platform contains references to third party websites and relies on third party services for support. We shall not be liable for any third party venues and cannot guarantee their performance. We do not monitor all content submitted to the Platform. We shall not be liable for user submissions or any third party content on the Platform.</p>
                        
                        <p>e)	<em>No Professional Advice Whatsoever.</em>  Nothing on our Platform constitutes legal, career or any other type of professional advice on our part. Your use of the Platform does not form an attorney-client, employer-employee or any other professional relationship between you and us. If you engage anybody listed on our Platform, we are not a party to your interactions and transactions.</p>

                        
                        <p><b><u>3.	INTELLECTUAL PROPERTY</u></b></p>
                        
                        <p>a)	Our Intellectual Property. We and our content suppliers own all intellectual property rights in our Platform contents, logos, trademarks (whether registered or unregistered) and data. Our IP rights are protected by U.S. law and international IP conventions. By using our Platform you do not acquire any of our IP rights. Nevertheless, you can view and print out this Platform’s content for personal use. We reserve all rights that are not expressly granted under these Terms or other written agreements between you and us.</p>
                        
                        <p>b)	Your Submissions. We do not claim ownership rights over your content. What’s yours remains yours. However, if you upload any content to the public areas of our Platform, you state that: (i) you have all necessary rights to that content, and (ii) we can display, transmit, modify and distribute this content without compensation to you. We can use and implement any feedback that you voluntarily provide, without compensation to you.</p>
                        
                        <p>c)	Copyright Infringement. We take copyright infringement seriously. Report it to us if you see it on our Platform and we will investigate.  In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S. Copyright Office website at <a href="http://www.copyright.gov/legislation/dmca.pdf" target="_blank">http://www.copyright.gov/legislation/dmca.pdf</a>, we will promptly investigate claims of copyright infringement committed using our Platform if such claims are <a href="/contact-us">reported here</a>. If you hold copyright or are authorized to act on behalf of the copyright holder, you can report alleged copyright infringements as follows:</p>

                        
                        <p>&emsp;<em>i.</em>	Identify the copyrighted work that you claim has been infringed.</p>
                        
                        <p>&emsp;<em>ii.</em>	Identify the material or link you claim is infringing.</p>
                        
                        <p>&emsp;<em>iii.</em>	Include both of the following statements in the body of your report:</p>
                        
                        <p>&emsp;&emsp;<em>-</em>	"I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)"</p>
                        
                        <p>&emsp;&emsp;<em>-</em>	"I hereby state that the information in this report is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of, the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed."</p>
                        
                        <p>&emsp;<em>iv.</em>	Provide your full legal name and your electronic or physical signature.</p>

                        
                        <p><b><u>4.	ACCEPTABLE USE POLICY</u></b></p>
                        
                        <p>By visiting this Platform, you represent and agree that:</p>
                        
                        <p>a)	You have a full capacity to enter into a legally binding agreement, such as these Terms.</p>
                        
                        <p>b)	You will not let others use your account, except as may be explicitly authorized by us. Everything that happens under your account is your responsibility. Registering duplicate accounts is not allowed.</p>
                        
                        <p>c)	If you make a submission, it shall be truthful and not misleading. We can terminate any account for writing untruthful reviews, comments or other content. We reserve the right to edit, reject or erase anything submitted to us without prior notice. You will not send spam, anything defamatory, vulgar, racist, abusive or hateful.</p>
                        
                        <p>d)	You will not use our Platform in connection with any sexually explicit material, illegal drugs, promotion of alcohol to persons under 21 years of age, pirated computer programs, viruses or other harmful code, disclosure of anyone's private information without consent, pyramid schemes, multilevel-marketing, "get rich quick" offerings, encouragement of violence.</p>
                        
                        <p>e)	You will ask for our permission before copying anything from our Platform for republication.</p>
                        
                        <p>f)	You will not use our Platform for anything illegal.</p>
                        
                        <p>g)	We reserve the right to terminate any account using our sole reasonable discretion and without notice or liability.</p>
                        
                        <p>h)	Bots, crawlers, indexers, web spiders, harvesters or any similar automatic processes are not allowed on our Platform.</p>
                        
                        <p>i)	You will not impede the proper functioning of the Platform.</p>

                        
                        <p><b><u>5.	CONFIDENTIALITY</u></b></p>
                        
                        <p>You cannot use or disclose any confidential information relating to our business, users, operations and properties for any purpose without our express prior written authorization. You agree to take all reasonable measures to protect the secrecy of and avoid disclosure or use of our confidential information.</p>

                        
                        <p><b><u>6.	BREACH OF THESE TERMS</u></b></p>
                        
                        <p>If any user violates these Terms or any law, we can, without limitation: (i) ban that user from the Platform; (ii) disclose the user’s identity to authorities and assist in investigations; (iii) delete or moderate the user’s content; (iv) take any other action available under law.</p>

                        
                        <p><b><u>7.	DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY</u></b></p>
                        
                        <p>a)	EVERYTHING WE PROVIDE ON THIS PLATFORM IS ON AN "AS IS" BASIS, TO BE RELIED ON AT YOUR OWN RISK. DO YOUR OWN RESEARCH BEFORE RELYING ON ANYTHING ON THIS PLATFORM.  WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, NON-INFRINGEMENT, SAFETY, FREEDOM FROM DEFECTS OR THAT DEFECTS WILL BE CORRECTED, UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE PERFORMANCE.</p>
                        
                        <p>b)	WE ARE NOT LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS AND PROPERTY DAMAGE, EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, NOR SHALL WE BE HELD LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE CONTROL.  IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED THE FEES WE RECEIVED FROM YOU, IF ANY.  SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF CERTAIN WARRANTIES, SO THE ABOVE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.</p>

                        
                        <p><b><u>8.	INDEMNIFICATION</u></b></p>
                        
                        <p>You agree to defend, indemnify and hold harmless us, our company, its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Platform; (ii) your violation of any provision of these Terms; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that one of your user submissions caused damage to a third party.</p>

                        
                        <p><b><u>9.	ARBITRATION; CLASS ACTION WAIVER</u></b></p>
                        
                        <p>a)	<em>Arbitration.</em> Any controversy or claim arising out of or relating to these Terms, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The arbitration shall be governed by the laws of the State of New York. The arbitration will be based on the submission of documents and there shall be no in-person or oral hearing. Except as may be required by law, neither a party nor an arbitrator may disclose the existence, content, or results of any arbitration hereunder without the prior consent of all parties. You understand that, by using the Platform, you agree to arbitrate, thus, waiving your rights to sue in court and have a jury trial.</p>
                        
                        <p>b)	<b><em>Class Action Waiver.</em> You acknowledge and agree that you waive your right to participate as a plaintiff or class member in any purported class action or representative proceeding. Further, unless both you and us otherwise agree in writing, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of any class or representative proceeding.</b></p>

                        
                        <p><b><u>10.	GENERAL</u></b></p>
                        
                        <p>a)	<em>Communications.</em>  You agree that we can communicate with you electronically, via SMS, push notifications, email or phone calls. All electronic communications shall have the same legal force as if they were in paper form.</p>
                        
                        <p>b)	<em>Relationship of the Parties.</em>  You and us are in an independent contractor relationship with respect to each other. That means that there is no partnership, joint venture, employer/employee or any similar arrangement.</p>
                        
                        <p>c)	<em>Force Majeure.</em>  We will not be liable for failure to perform any obligations to the extent that the failure is caused by a Force Majeure event such as, without limitation, act of God, riot, civil disturbances, acts of terrorism, fire, explosion, flood, epidemic, national mourning, theft of essential equipment, malicious damage, strike, lock out, weather, third party injunction, acts or regulations of national or local governments.</p>
                        
                        <p>d)	<em>Hyperlinks.</em>  Linking to our Platform is allowed, however, it must always be done in a way that does not adversely affect our business or implies some form of association when there is none.</p>
                        
                        <p>e)	<em>Severability.</em>  If any part of these Terms is found to be unenforceable, then only that particular portion, and not the entire Terms, will be unenforceable.</p>
                        
                        <p>f)	<em>Assignment.</em>  We have the right, at our sole discretion, to assign or subcontract our rights or obligations outlined in these Terms.</p>
                        
                        <p>g)	<em>Waiver.</em>  Our failure to exercise any of our rights under these Terms shall not be considered a waiver to exercise them in other instances. No waiver shall be effective unless it is in writing signed by us.</p>
                        
                        <p>h)	<em>Prevailing Language.</em>  If there are any inconsistencies or conflicts between the English original of these Terms and any foreign language translation, the English version shall prevail.</p>

                        
                        <p><b><u>11.	CONTACT US</u></b></p>
                        
                        <p>Please address your <a href="/contact-us">questions and feedback here</a>.</p>

                        </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutMain